import './App.css';
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  BanIcon,
  CalendarIcon,
  MenuIcon,
  ClipboardListIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
  SearchIcon,
  ChatIcon,
  FlagIcon,
  PhoneIcon,
  DocumentTextIcon,
  DesktopComputerIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import {
  Link
} from "react-router-dom";
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#',
    icon: SupportIcon,
  },
  {
    name: 'Guides',
    description: 'Learn how to maximize our platform to get the most out of it.',
    href: '#',
    icon: BookmarkAltIcon,
  },
  {
    name: 'Events',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon,
  },
  { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#', icon: ShieldCheckIcon },
]

const features = [
  {
    name: 'Understand the plan',
    icon: ClipboardListIcon,
  },
  {
    name: 'Follow along',
    icon: ChatIcon,
  },
  {
    name: 'Focus on features that matter',
    icon: SearchIcon,
  },
  {
    name: 'Finish strong',
    icon: FlagIcon,
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Home() {
  return (
    <div className="relative bg-gray-50">
      {/*<Popover className="relative bg-white shadow">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
              <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <a href="#">
                    <span className="sr-only">Workflow</span>
                    <img
                      className="h-8 w-auto sm:h-16"
                      src={process.env.PUBLIC_URL + '/gg.svg'}
                      alt=""
                    />
                  </a>
                </div>
                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <Popover.Group as="nav" className="hidden md:flex space-x-10">
                  <a href="/projects" className="text-base font-medium text-gray-500 hover:text-gray-900">
                    Our Work
                  </a>
                  <a href="/blog" className="text-base font-medium text-gray-500 hover:text-gray-900">
                    Blog
                  </a>

               </Popover.Group>
                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                  <a
                    href="https://calendly.com/good-gravy/free-45-minute-consultation"
                    className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-black bg-yellow-100

                    "
                  >
                    Free Consulation
                  </a>
                </div>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src={process.env.PUBLIC_URL + '/gg.svg'}
                          alt="Workflow"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                  <div className="py-6 px-5 space-y-6">
                    <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                      <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Pricing
                      </a>

                      <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Docs
                      </a>
                      {resources.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div>
                      <a
                        href="#"
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-yellow-600 hover:bg-yellow-700"
                      >
                        Sign up
                      </a>
                      <p className="mt-6 text-center text-base font-medium text-gray-500">
                        Existing customer?
                        <a href="#" className="text-yellow-600 hover:text-yellow-500">
                          Sign in
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
                      */ }
      <main className="lg:relative">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <img
                          className="h-32 w-auto mb-16 ml-4"
                          src={process.env.PUBLIC_URL + '/gg.svg'}
                          alt="Workflow"
                        />
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">Clarity over</span>{' '}
              <span className="block text-yellow-900 xl:inline">chaos</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            Good Gravy Plans & Builds Mobile & Web Apps
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="https://calendly.com/good-gravy/free-45-minute-consultation"
                  className="w-full flex items-center justify-center px-8 py-3 border border-black text-base font-medium rounded-md text-black bg-white-600 hover:bg-white-700 md:py-4 md:text-lg md:px-10"
                >
                  Schedule Free Consultation
                </a>
              </div>

            </div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={process.env.PUBLIC_URL + '/header.jpg'} 
            alt=""
          />
        </div>
        </main>

    <div className="bg-yellow-700">
      <div className="max-w-4xl mx-auto px-8 py-8 lg:max-w-7xl">
        <div className="grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="text-center">
              <div>
                  <feature.icon className="h-12 w-12 m-auto" aria-hidden="true" />
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-medium">{feature.name}</h3>
                <p className="mt-2 text-base text-yellow-200">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    <div className="bg-gray-700 text-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">

        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8 p-16">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              No more black boxes.
            </h2>
            <p>
            Most mobile & web app projects suffer from black box thinking. Ideas go into the black box, and (hopefully) come out the other side as you dreamed.


            </p>
          </div>
          <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
              <div key="waiting">
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md border-white border text-white">
                    <BanIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium ">The waiting is agony.</p>
                </dt>
                <dd className="mt-2 text-base ">What if it doesn't turn out? How can I trust a company I just started working with? How can I plan around this? No control!</dd>
              </div>
              <div key="iteration">
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md text-white border">
                    <BanIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium">Iteration is impossible.</p>
                </dt>
                <dd className="mt-2 text-base ">What if I didn't get the scope exactly right? What if what we're building isn't what customers need?</dd>
              </div>
              
          </dl>

        </div>
      </div>
    </div>

    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-yellow-900 font-semibold tracking-wide uppercase">Limit Chaos</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Take control of your idea.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div className="relative">
                <dt>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">ENGAGE THE PROCESS</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">The landscape of technology is complicated, but you’ll have a clear path through.</dd>
              </div>

              <div className="relative">
                <dt>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">KEEP THE PEACE</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">There will never be a moment where you aren’t aware of how your project is progressing in budget and timing.</dd>
              </div>

              <div className="relative">
                <dt>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">BECOME A MINIMALIST</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">Discover what features your customers actually need and get rid of the rest.</dd>
              </div>

              <div className="relative">
                <dt>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">SEE IT THROUGH</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">See your project in your customers hands.</dd>
              </div>
          </dl>
        </div>
      </div>
    </div>

    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Look, we get it.
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-500">
            You’re ready to get this done, but maybe you’ve been burned once or twice. Maybe apps are new to you. Maybe it’s just hard to get started.
            </p>
            <p className="mt-3 max-w-3xl text-lg text-gray-500">
            Creating apps for mobile & the web is tough, but that’s why we’re here. We have proven our success working with some amazing clients.</p>
            <div className="mt-8 sm:flex">
              <div className="rounded-md shadow">
                <a
                  href="https://calendly.com/good-gravy/free-45-minute-consultation"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
                >
                  Schedule Free Consultation
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-16"
                src={process.env.PUBLIC_URL + '/logos/rvc.png'} 
                alt="Workcation"
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img className="max-h-12" src={process.env.PUBLIC_URL + '/logos/sb.png'}  alt="Mirage" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img className="max-h-8" src={process.env.PUBLIC_URL + '/logos/vimbibe.png'} alt="Tuple" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-8"
                src={process.env.PUBLIC_URL + '/logos/voloco.png'}
                alt="Laravel"
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-12"
                src={process.env.PUBLIC_URL + '/logos/sunsett.png'}
                alt="StaticKit"
              />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-12"
                src={process.env.PUBLIC_URL + '/logos/em.png'}
                alt="Statamic"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="work" className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Latest Work</h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Here's a few case studies of our latest work.
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-none">
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover" src="/projects/yw/hero.png" alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-yellow-900">
                    Web App
                  </p>
                  <Link className="block mt-2" to={"/projects/1"}>
                    <p className="text-xl font-semibold text-gray-900">Youthworks</p>
                    <p className="mt-3 text-base text-gray-500">Transform a paper-driven camp registation process to a digital process.</p>
                  </Link>
                </div>
            
              </div>
            </div>
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover" src="/projects/vb/hero.png" alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-yellow-900">
                    Web App
                  </p>
                  <Link className="block mt-2" to={"/projects/2"}>
                    <p className="text-xl font-semibold text-gray-900">Vimbibe</p>
                    <p className="mt-3 text-base text-gray-500">Enable wineries to connect with their customers over text.</p>
                  </Link>
                </div>
            
              </div>
            </div>
        </div>
      </div>
    </div>

    <div className="py-12 bg-white">
    <div class="lg:text-center"><h2 class="text-base text-yellow-900 font-semibold tracking-wide uppercase">You ready?</h2><p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">How it works</p></div>
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 mt-10">
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
                  <PhoneIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">Schedule a Call</p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">We’ll learn your lingo. We’ll understand your processes backwards and forwards and sideways.</dd>
            </div>

            <div>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
                  <DocumentTextIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">Make a Plan</p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">We’ll set clear expectations of what our engagement will look like. We’ll build a backlog of the work to be done and set benchmarks to facilitate quality communication.</dd>
            </div>

            <div>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
                  <DesktopComputerIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">Build the App</p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">We’ll do what we do best and build out what we’ve planned.</dd>
            </div>
        </dl>
      </div>
    </div>

    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-yellow-900 font-semibold tracking-wide uppercase">
              Why
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Good Gravy
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
          A lot of people feel that building a mobile or web app is inherently a wild ride.
          </p>

          <p className="mt-8 text-xl text-gray-500 leading-8">
          We created Good Gravy under the premise that there has to be a better way to do things, with less chaos and more care for our craft. We take a limited number of projects and focus on them with more attention, in order to serve our customers well. We’re transparent, end-user focused, and really good at what we do.
          </p>
          
        </div>
      </div>
      </div>
    
      <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
   </div>
   <div className="relative px-4 sm:px-6 lg:px-8 bg-gray-50 py-24">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Free 45 Minute Consultation
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
          We can help get you moving in the right direction for you mobile or web app. No high pressure sales, just honest to goodness advice.
          </p>

          <div class="mt-8 sm:flex"><div class="rounded-md shadow"><a href="https://calendly.com/good-gravy/free-45-minute-consultation" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700">Schedule Free Consultation</a></div></div>

          <p className="mt-8 text-xl text-gray-500 leading-8">
          Prefer email? Email us at hey@goodgravy.digital</p>
          
        </div>
      </div>

                  <div class="relative px-4 sm:px-6 lg:px-8 bg-gray-50 py-24"><div class="text-lg max-w-prose mx-auto"><h1><span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Currently Working On</span></h1><p class="mt-8 text-xl text-gray-500 leading-8">Currently building a new app, Happy Signups, focused on <a class="underline" href="https://www.happysignups.com">creating signup lotteries for high demand events and programs</a>. </p></div></div>
     

      <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">About Us</h2>
            <p className="text-xl text-gray-500">
              Good Gravy is Philip Grey, a very experienced developer from Minneapolis, MN. 
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
                <li className="sm:py-8">
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                      <img className="object-cover shadow-lg rounded-lg" src={process.env.PUBLIC_URL + '/staff/phil.jpeg'} alt="" />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3>Philip Grey</h3>
                          <p className="text-yellow-900">Owner, Technical Architect</p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500">Phil has a distinct memory of sitting on the bus when he was in high school, hand writing code in a notebook to try when he got home.</p>
                          <p className="text-gray-500 mt-8">He has been working with the web for ten years. He started off as a digital developer at Periscope in Minneapolis, building websites for Fortune 500 brands like Exxon Mobile and Red Robin. After three years, he left Periscope to forge a successful freelance career, working with great clients building websites and web applications. Finally, he founded Good Gravy in 2018 in order to pursue a lifelong passion for helping people using technology.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
    
    
    </div>
  );
}

export default Home;
